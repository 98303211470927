import { Injectable, computed, inject } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveUiService {
  breakpointObserver = inject(BreakpointObserver);

  observer = toSignal(
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.WebPortrait,
      Breakpoints.Tablet,
      Breakpoints.WebLandscape,
      '(min-width: 600px)',
      '(min-width: 768px)',
      '(min-width: 960px)',
      '(min-width: 1280px)',
      '(min-width: 1920px)',
    ])
  );

  isMobileDevice = computed(
    () => this.observer()?.breakpoints[Breakpoints.XSmall]
  );

  isTabletScreen = computed(
    () =>
      this.observer()?.breakpoints[Breakpoints.Tablet] ||
      this.observer()?.breakpoints[Breakpoints.WebPortrait]
  );

  isBrowserRes = computed(
    () => this.observer()?.breakpoints[Breakpoints.WebLandscape]
  );

  sm = computed(() => this.observer()?.breakpoints['(min-width: 600px)']);
  md = computed(() => this.observer()?.breakpoints['(min-width: 768px)']);
  lg = computed(() => this.observer()?.breakpoints['(min-width: 960px)']);
  xl = computed(() => this.observer()?.breakpoints['(min-width: 1280px)']);
  xxl = computed(() => this.observer()?.breakpoints['(min-width: 1920px)']);

  isHandheldScreenSizes$(): Observable<BreakpointState> {
    return this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
    ]);
  }
}
