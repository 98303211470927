import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule],
  selector: 'app-base-dialog',
  template: `
    <div class="modal-container">
      <div class="modal-header">
        <h3>{{ headerText | translate }}</h3>
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          data-dismiss="modal"
          tabindex="-1"
          (click)="dismissOverride ? dismissOverride() : modal.dismiss()"
        ></button>
      </div>

      <ng-content #bodyWrapper></ng-content>
    </div>
  `,
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent implements OnInit {
  @ViewChild('bodyWrapper', {
    static: true,
  })
  bodyWrapper?: ElementRef;

  @Input() headerText = 'FORM_DIALOG_HEADER';
  @Input() dismissOverride?: () => void;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  dismiss() {
    this.modal.dismiss();
  }
}
